import classNames from 'classnames';

import { FileDropzonePublicProps } from '../types';

type Props = { displayMode: FileDropzonePublicProps['displayMode']; small?: boolean };

export const makeContainerClassNamesBySizeAndVariant = ({
  small,
  displayMode,
}: Props): string | undefined => {
  switch (displayMode) {
    case 'standard':
      return classNames(
        'min-h-3xl rounded-xs',
        small ? 'p-sm' : 'sm-desktop:min-h-[80px] sm-desktop:p-md p-[22px]',
      );
    case 'pictures-wall':
      return classNames(
        'flex flex-col justify-center items-center rounded-xxs p-sm pb-xs',
        small ? 'size-3xl' : 'size-[5rem]',
      );
  }
};
