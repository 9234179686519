/* eslint-disable react/button-has-type */ // Type is given by callee
import React from 'react';

import { Placement } from '@floating-ui/react';
import classNames, { Argument } from 'classnames';

import { BadgeVariant } from '@travauxlib/shared/src/components/DesignSystem/components/Badge/types';
import {
  ExtendedPaletteVariants,
  Variant as VariantDS,
} from '@travauxlib/shared/src/types/designSystem';

import { ActionDisabledWithTooltip } from './ActionDisabledWithTooltip';

import { Badge } from '../Badge';

export type IconSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type Variant = Exclude<VariantDS, 'light'> | Extract<ExtendedPaletteVariants, 'beige'>;

const iconButtonClassesBySize: { [key in IconSizes]: Argument[] } = {
  xxs: ['w-sm', 'h-sm'],
  xs: ['w-md', 'h-md'],
  sm: ['w-lg', 'h-lg'],
  md: ['w-xl', 'h-xl'],
  lg: ['w-xxl', 'h-xxl'],
  xl: ['w-xxl', 'h-xxl'],
};

const iconContainerClassesBySize: { [key in IconSizes]: Argument[] } = {
  xxs: ['w-sm', 'h-sm'],
  xs: ['w-md', 'h-md'],
  sm: ['w-md', 'h-md'],
  md: ['w-lg', 'h-lg'],
  lg: ['w-lg', 'h-lg'],
  xl: ['w-xl', 'h-xl'],
};

const iconButtonClassesDisabledByVariant: { [key in Variant]: Argument[] } = {
  white: ['text-neutral-300'],
  grey: ['text-neutral-300'],
  error: ['text-error-50'],
  success: ['text-success-50'],
  warning: ['text-warning-50'],
  info: ['text-info-50'],
  primary: ['text-neutral-300'],
  black: ['text-neutral-300'],
  beige: ['text-neutral-300'],
};

const iconButtonClassesActiveByVariant: { [key in Variant]: Argument[] } = {
  white: ['text-neutral-900 bg-neutral-200'],
  grey: ['text-neutral-900 bg-neutral-300'],
  error: ['text-error-900 bg-error-200'],
  success: ['text-success-900 bg-success-200'],
  warning: ['text-warning-900 bg-warning-300'],
  info: ['text-info-900 bg-info-200'],
  primary: ['text-neutral-100 bg-primary-600'],
  black: ['text-neutral-100 bg-neutral-600'],
  beige: ['text-primary-600 bg-beige-200'],
};

const iconButtonClassesByVariant: { [key in Variant]: Argument[] } = {
  white: [
    'text-neutral-800 hover:text-neutral-700 focus-visible:text-neutral-700 active:text-neutral-900 disabled:text-neutral-300',
    'bg-transparent hover:bg-neutral-100 focus-visible:bg-neutral-100 active:bg-neutral-200 disabled:bg-transparent',
  ],
  grey: [
    'text-neutral-700 hover:text-neutral-800 focus-visible:text-neutral-800 active:text-neutral-900 disabled:text-neutral-300',
    'bg-transparent hover:bg-neutral-200 focus-visible:bg-neutral-200 active:bg-neutral-300 disabled:bg-transparent',
  ],
  error: [
    'text-error-800 hover:text-error-700 focus-visible:text-error-700 active:text-error-900 disabled:text-neutral-300',
    'bg-transparent hover:bg-error-100 focus-visible:bg-error-100 active:bg-error-200 disabled:bg-transparent',
  ],
  success: [
    'text-success-800 hover:text-success-900 focus-visible:text-success-900 active:text-success-900 disabled:text-neutral-300',
    'bg-transparent hover:bg-success-100 focus-visible:bg-success-100 active:bg-success-200 disabled:bg-transparent',
  ],
  warning: [
    'text-warning-800 hover:text-warning-700 focus-visible:text-warning-700 active:text-warning-900 disabled:text-neutral-300',
    'bg-transparent hover:bg-warning-200 focus-visible:bg-warning-200 active:bg-warning-300 disabled:bg-transparent',
  ],
  info: [
    'text-info-800 hover:text-info-700 focus-visible:text-info-700 active:text-info-900 disabled:text-neutral-300',
    'bg-transparent hover:bg-info-100 focus-visible:bg-info-100 active:bg-info-200 disabled:bg-transparent',
  ],
  primary: [
    'text-neutral-0 hover:text-neutral-100 focus-visible:text-neutral-100 active:text-neutral-100 disabled:text-neutral-300',
    'bg-primary-400 hover:bg-primary-500 focus-visible:bg-primary-500 active:bg-primary-600 disabled:bg-transparent',
  ],
  black: [
    'text-neutral-0 hover:text-neutral-100 focus-visible:text-neutral-100 active:text-neutral-100 disabled:text-neutral-300',
    'bg-transparent hover:bg-neutral-600 focus-visible:bg-neutral-600 active:bg-neutral-500 disabled:bg-transparent',
  ],
  beige: [
    'text-primary-400 hover:text-primary-500 focus-visible:text-primary-500 active:text-primary-600 disabled:text-neutral-300',
    'bg-transparent hover:bg-beige-100 focus-visible:bg-beige-100 active:bg-beige-200 disabled:bg-transparent',
  ],
};

const buttonCssReset = 'p-0';

export const makeIconButtonClassnames = ({
  size,
  variant,
  disabled = false,
  isActive = false,
}: {
  size: IconSizes;
  variant: Variant;
  disabled?: boolean;
  isActive?: boolean;
}): Argument[] => [
  buttonCssReset,
  'flex justify-center items-center',
  'rounded-full border-0',
  'focus:outline focus:outline-1 focus:outline-blue-500',
  ...(disabled
    ? iconButtonClassesDisabledByVariant[variant]
    : isActive
      ? iconButtonClassesActiveByVariant[variant]
      : iconButtonClassesByVariant[variant]),
  ...iconButtonClassesBySize[size],
  {
    'bg-transparent pointer-events-none': disabled,
  },
];

export const IconButtonContent: React.FC<{ size: IconSizes; children: React.ReactNode }> = ({
  children,
  size,
}) => (
  <span
    className={classNames(
      'flex justify-center items-center shrink-0',
      ...iconContainerClassesBySize[size],
    )}
  >
    {children}
  </span>
);

export type CustomIconButtonProps = {
  variant?: Variant;
  size?: IconSizes;
  disabled?: boolean;
  isActive?: boolean;
  badgeValue?: number;
  badgeVariant?: BadgeVariant;
  children?: React.ReactNode;
  disabledMessageTooltip?: string;
  positionTooltip?: Placement;
  dataTestId?: string;
};

export type IconButtonProps = CustomIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      size = 'md',
      children,
      variant = 'white',
      className,
      badgeValue,
      disabledMessageTooltip,
      positionTooltip,
      badgeVariant = 'light',
      type = 'button',
      isActive,
      dataTestId,
      ...rest
    },
    ref,
  ) => {
    const isBadgeSize = size === 'xs' || size === 'sm' || size === 'md';

    return (
      <ActionDisabledWithTooltip
        disabled={rest.disabled}
        disabledMessage={disabledMessageTooltip}
        position={positionTooltip}
      >
        <button
          ref={ref}
          type={type}
          data-testid={dataTestId || 'icon-button-container'}
          className={classNames(
            makeIconButtonClassnames({
              size,
              variant,
              isActive: isActive,
              disabled: rest.disabled,
            }),
            className,
            { relative: badgeValue },
          )}
          {...rest}
        >
          {isBadgeSize && badgeValue ? (
            <div className="absolute left-1/2 bottom-1/2">
              <Badge size={size} variant={badgeVariant} value={badgeValue} />
            </div>
          ) : null}
          <IconButtonContent size={size}>{children}</IconButtonContent>
        </button>
      </ActionDisabledWithTooltip>
    );
  },
);
