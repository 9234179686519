import React from 'react';

import classNames from 'classnames';

import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { ExtendedBrandVariants } from '@travauxlib/shared/src/types/designSystem';

type AvatarSizes = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export type Props = {
  className?: string;
  variant?: Exclude<ExtendedBrandVariants, 'grey'>;
  size?: AvatarSizes;
  pictureURL?: string;
  firstName?: string;
  lastName?: string;
  text?: string;
} & Omit<React.HTMLProps<HTMLDivElement>, 'size'>;

const avatarClassNamesBySizes = {
  xl: '!h-[5.25rem] !w-[5.25rem]',
  lg: '!h-xxl !w-xxl',
  md: '!h-xl !w-xl',
  sm: '!h-lg !w-lg',
  xs: '!h-xmd !w-xmd',
};

export const avatarClassNamesByColor = {
  primary: 'bg-primary',
  green: 'bg-green-400',
  blue: 'bg-blue-400',
  lightgreen: 'bg-lightgreen-400',
  beige: 'bg-beige-400',
  white: 'bg-neutral-200',
  black: 'bg-neutral-800',
  light: 'bg-neutral-400',
};

const avatarContainerClassNamesBySizes = {
  xl: 'h-xxl w-xxl',
  lg: 'h-xl w-xl',
  md: 'h-md w-md',
  sm: 'h-sm w-sm',
  xs: 'h-xs w-xs',
};

const avatarFontSizesByClassName = {
  xl: 'text-h3',
  lg: 'text-b1',
  md: 'text-b2',
  sm: 'text-sm',
  xs: 'text-xs',
};

export const Avatar: React.FC<Props> = ({
  size = 'md',
  variant = 'primary',
  pictureURL,
  firstName,
  lastName,
  text,
  className,
  disabled,
  ...rest
}) => {
  const maybeInitials = firstName && lastName ? `${firstName?.[0]}${lastName?.[0]}` : undefined;
  const latinInitials =
    maybeInitials && /^[a-zA-Z]+$/.test(maybeInitials) ? maybeInitials : undefined;

  const content = text || latinInitials;
  const childComponent = pictureURL ? (
    <ImgLazy
      sizes="100%"
      url={pictureURL}
      alt={lastName}
      className={classNames('rounded-full object-cover', avatarClassNamesBySizes[size])}
    />
  ) : content ? (
    <span className={classNames('font-bold', avatarFontSizesByClassName[size])}>{content}</span>
  ) : (
    <Person data-testid="avatar-icon-fallback" className={avatarContainerClassNamesBySizes[size]} />
  );

  return (
    <div
      className={classNames(
        className,
        avatarClassNamesBySizes[size],
        avatarClassNamesByColor[variant],
        'flex items-center justify-center rounded-full text-neutral-0',
        { 'bg-neutral-300': disabled },
      )}
      {...rest}
    >
      {childComponent}
    </div>
  );
};
