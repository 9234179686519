import React from 'react';

import classNames from 'classnames';
import Dropzone from 'react-dropzone';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { formatFileSize } from '@travauxlib/shared/src/utils/format';

import { DropZoneError } from './DropZoneError';
import { DropZoneInitial } from './DropZoneInitial';
import { DropZoneProgress } from './DropZoneProgress';
import { FileDropzonePrivateProps, FileDropzonePublicProps } from './types';
import { makeContainerClassNamesBySizeAndVariant } from './utils';

export const DEFAULT_SIZE_LIMIT = 8000000;

const RawFileDropzoneContainer: React.FC<{
  disabled?: boolean;
  dragInProgress?: boolean;
  small?: boolean;
  hasErrors?: boolean;
  children: React.ReactNode;
  displayMode: FileDropzonePublicProps['displayMode'];
}> = ({ children, disabled, dragInProgress, small, hasErrors, displayMode }) => (
  <div
    className={classNames(
      'outline-dashed outline-1 bg-neutral-100',
      makeContainerClassNamesBySizeAndVariant({ displayMode, small }),
      disabled
        ? 'outline-neutral-300 cursor-not-allowed'
        : 'outline-neutral-600  hover:outline-primary active:outline-primary-600',
      { 'outline-[2px] m-[-1px] bg-neutral-200': dragInProgress },
      { '!outline-error-800 !pb-xs': hasErrors },
    )}
  >
    {children}
  </div>
);

const errorMessages = (): { [key: string]: string } => ({
  'file-too-large': `Fichier trop gros - ${formatFileSize(DEFAULT_SIZE_LIMIT)} maximum`,
  'file-invalid-type': `Fichier invalide - type de fichiers non autorisé`,
  'file-too-small': 'Fichier trop petit',
  'too-many-files': "Trop de fichiers d'un coup",
});

export const FileDropzone: React.FC<FileDropzonePrivateProps & FileDropzonePublicProps> = ({
  progress,
  handleUploadFile,
  multiple = false,
  hasErrors,
  onClickRetry,
  sizeLimit,
  disabled,
  small = false,
  id = 'dropzone',
  displayMode = 'standard',
}) => {
  const [dragInProgress, setDragInProgress] = React.useState<boolean>(false);
  const onDrop = (droppedFiles: File[]): Promise<any> => handleUploadFile(droppedFiles);

  const disableDrop = !!progress || hasErrors || disabled;

  return (
    <Dropzone
      disabled={disableDrop}
      multiple={multiple}
      onDrop={onDrop}
      onDragEnter={() => setDragInProgress(true)}
      onDragLeave={() => setDragInProgress(false)}
      onDropRejected={events => {
        const errors = events.flatMap(event => event.errors);
        errors.forEach(error => {
          const errorMessage =
            errorMessages()[error.code] || 'Erreur inconnue - Veuillez contacter notre support.';
          toast.error(errorMessage);
        });
      }}
      maxSize={sizeLimit}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          role={disableDrop ? 'div' : 'button'}
          className={displayMode === 'pictures-wall' ? 'w-fit' : ''}
        >
          <RawFileDropzoneContainer
            disabled={disabled}
            dragInProgress={dragInProgress}
            small={small}
            hasErrors={hasErrors}
            displayMode={displayMode}
          >
            <input {...getInputProps()} data-testid="dropzone" />
            {progress !== undefined ? (
              <DropZoneProgress progress={progress} displayMode={displayMode} />
            ) : hasErrors ? (
              <DropZoneError
                onClickRetry={onClickRetry}
                multipleUpload={multiple}
                displayMode={displayMode}
                small={small}
              />
            ) : (
              <DropZoneInitial
                disabled={disabled}
                small={small}
                id={id}
                displayMode={displayMode}
              />
            )}
          </RawFileDropzoneContainer>
        </div>
      )}
    </Dropzone>
  );
};
