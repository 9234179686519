import React from 'react';

import classNames from 'classnames';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import Search from '@travauxlib/shared/src/components/DesignSystem/assets/Search.svg?react';

import { IconButton } from '../Buttons/IconButton';
import { Input } from '../Input';

type Props = {
  value: string;
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  autoFocus?: boolean;
  onSearch?: React.MouseEventHandler<HTMLButtonElement>;
  placeholder?: string;
};

export const SearchBar: React.FC<Props> = ({
  value,
  onChange,
  className,
  disabled = false,
  fullWidth = false,
  autoFocus = false,
  onSearch,
  placeholder,
}) => (
  <>
    <Input
      autoFocus={autoFocus}
      className={classNames(className, { 'flex grow': fullWidth })}
      data-testid="searchbar"
      id="searchbar"
      value={value ?? ''}
      onChange={onChange}
      placeholder={placeholder || 'Rechercher'}
      disabled={disabled}
      renderingOptions={{
        containerClassName: classNames('rounded-full', { grow: fullWidth }),
        inputClassName: 'rounded-full',
      }}
      suffix={
        <div className={classNames('flex flex-row', { '-ml-[2.25rem]': value })}>
          <div
            className={classNames('flex items-center mr-xxs', {
              hidden: !value,
            })}
          >
            <IconButton
              disabled={disabled}
              onClick={() => onChange?.('')}
              className={classNames({ 'text-neutral-300': disabled })}
            >
              <CrossSymbol />
            </IconButton>
          </div>
          <div className="flex items-center">
            <IconButton
              variant={disabled ? 'grey' : 'primary'}
              disabled={disabled}
              onClick={onSearch}
              className={classNames({ 'bg-primary': !disabled })}
            >
              <Search />
            </IconButton>
          </div>
        </div>
      }
    />
  </>
);
