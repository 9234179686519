import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const architecteInterneBuilder = new AbilityBuilder<Action, Subject, Flag>();

architecteInterneBuilder.can('manage', 'Lead');
architecteInterneBuilder.can('open', 'Chantier');
architecteInterneBuilder.can('close', 'Chantier');
architecteInterneBuilder.can('createAppelPaiement', 'Chantier');

export const architecteInterne = architecteInterneBuilder.build();
