import React from 'react';

import classNames from 'classnames';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import DeleteOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteOutline.svg?react';
import PlaceholderPicture from '@travauxlib/shared/src/components/DesignSystem/assets/PlaceHolderPicture.svg?react';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { DownloadableFile } from '@travauxlib/shared/src/types';
import { isURLAnImage } from '@travauxlib/shared/src/utils/urls';

import { FileDropzonePublicProps, FileListingTrackingProps } from './types';

import { IconButton } from '../Buttons/IconButton';
import { Link } from '../Links';

type Props = {
  file: DownloadableFile;
  onRemoveFile?: (file: DownloadableFile) => void;
  displayMode: FileDropzonePublicProps['displayMode'];
  small?: boolean;
  controls?: React.ReactNode;
};

export const FileDropzonePreview: React.FC<Props & FileListingTrackingProps> = ({
  file,
  onRemoveFile,
  trackingDownloadProps,
  displayMode,
  small,
  controls,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const isFileImage = isURLAnImage(file.name);

  if (displayMode === 'pictures-wall') {
    return (
      <div className={classNames('w-fit rounded-xxs relative', small ? 'size-3xl' : 'size-[5rem]')}>
        {isFileImage && file.imgixUrl ? (
          <img
            className={classNames('object-cover rounded-xxs', small ? 'size-3xl' : 'size-[5rem]')}
            data-testid={file.uuid}
            src={file.imgixUrl}
            alt={file.name}
          />
        ) : (
          <PlaceholderPicture
            className={classNames(small ? 'size-3xl' : 'size-[5rem]', 'shrink-0')}
          />
        )}
        {(onRemoveFile || controls) && (
          <div className="absolute top-xxs right-xxs flex gap-xxs p-xxs bg-neutral-0 border rounded-sm">
            {controls}
            {onRemoveFile && (
              <IconButton
                aria-label="remove-file"
                size={small ? 'xxs' : 'xs'}
                onClick={() => onRemoveFile(file)}
                variant="grey"
              >
                <DeleteOutline />
              </IconButton>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="border bg-neutral-0 p-xs rounded-xxs flex items-center mt-xs">
      <div className="mr-xs w-3xl h-3xl">
        {isFileImage && file.imgixUrl ? (
          <img
            className="w-3xl h-3xl object-cover"
            data-testid={file.uuid}
            src={file.imgixUrl}
            alt={file.name}
            height={isTabletOrMobile ? 48 : 64}
          />
        ) : (
          <div className="h-[48px] sm-desktop:h-[64] shrink-0">
            <PlaceholderPicture />
          </div>
        )}
      </div>
      <Link
        className="w-full !inline-block truncate text-ellipsis"
        href={file.downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
        {...trackingDownloadProps}
      >
        {file.name}
      </Link>
      {onRemoveFile && (
        <IconButton onClick={() => onRemoveFile(file)} className="shrink-0">
          <Delete data-testid="file-preview-delete-button" />
        </IconButton>
      )}
    </div>
  );
};
