import React from 'react';

import classNames from 'classnames';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';

type Props = {
  small?: boolean;
  hasErrors?: boolean;
  disabled?: boolean;
};

export const PicturesWallContent: React.FC<Props> = ({ small, hasErrors, disabled }) => (
  <div
    className={classNames('text-center text-neutral-600', {
      '!text-error': hasErrors,
      '!text-neutral-300': disabled,
    })}
  >
    <PlusSymbol className="size-lg mb-xxs" />
    <div className={small ? 'text-sm' : 'text-b2'}>Ajouter</div>
  </div>
);
