import React from 'react';

import { PicturesWallContent } from './PicturesWallContent';
import { FileDropzonePublicProps } from './types';

import { ProgressBar } from '../ProgressBar';

type Props = {
  onClickRetry: () => void;
  multipleUpload: boolean;
  displayMode: FileDropzonePublicProps['displayMode'];
  small?: boolean;
};
export const DropZoneError: React.FC<Props> = ({
  onClickRetry,
  multipleUpload,
  displayMode,
  small,
}) => {
  if (displayMode === 'pictures-wall') {
    return <PicturesWallContent small={small} hasErrors />;
  }

  return (
    <>
      <ProgressBar value={100} size="sm" variant="error" />
      <>
        <div className="text-xs sm-desktop:text-b2 mt-xs text-center text-error-800 sm-desktop:mb-xxs">
          Le téléchargement {multipleUpload ? "d'un ou plusieurs fichiers" : 'du fichier'} a échoué
        </div>
        <div
          className="cursor-pointer text-xs hover:underline sm-desktop:text-b1 text-neutral-800 font-medium text-center mt-xxs"
          onClick={onClickRetry}
        >
          Réessayer
        </div>
      </>
    </>
  );
};
